import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuGroup,
  MenuItem,
  HStack,
  chakra,
  Icon,
  Flex,
} from '@chakra-ui/react'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { BsTranslate } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa'
import { languages } from '../lib/languages'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

export default function LanguageSelector() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find(l => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<BsTranslate />}
        colorScheme='primary'
      />
      <MenuList>
        <MenuGroup title={t('language')}>
          {languages.map(({ code, name, country_code }) => {
            return (
              <MenuItem
                onClick={() => {
                  i18next.changeLanguage(code)
                }}
                key={code}
              >
                <chakra.span>{getUnicodeFlagIcon(country_code)}</chakra.span>
                <chakra.span px={4}>{name}</chakra.span>
                {currentLanguageCode === code && (
                  <Icon as={FaCheck} color='green.500' ml='auto' />
                )}
              </MenuItem>
            )
          })}
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}
