export const VEHICLE_TYPE = {
  TAXI: 'TAXI',
  EXECUTIVE_TAXI: 'EXECUTIVE_TAXI',
  AMBULANCE: 'AMBULANCE',
  BOMBEROS: 'BOMBEROS',
  DOCTOR: 'DOCTOR',
  CARABINEROS: 'CARABINEROS',
  GRUA: 'GRUA',
  MECANICO: 'MECANICO',
  PATROL: 'PATROL',
  PATROL1: 'PATROL1',
  PROMO: 'PROMO',
  REDCROSS: 'REDCROSS',
}

export const TRIP_STATUS = {
  pending: 'PENDING',
  accepted: 'ACCEPTED',
  declined: 'DECLINED',
  started: 'STARTED',
  completed: 'COMPLETED',
  cancelled: 'CANCELLED',
}

export const DRIVER_GENDER = {
  male: 'MALE',
  female: 'FEMALE',
  all: 'ALL',
}
