import { Marker } from '@react-google-maps/api'
import React from 'react'
import useSWR from 'swr'
import { useGeolocation } from '../contexts/GeolocationContext'
import api from '../lib/api-client'
import { markerIcon } from '../lib/market-icon'
import moment from 'moment'

export default function Drivers({ drivers, setSelectedDriver }) {
  const { position } = useGeolocation()

  // if (driverStatus === 'LAST_SEEN_10_DAYS') {
  //   console.log('LAST_SEEN_10_DAYS')
  //   filtered = filtered.filter(driver => {
  //     const lastSeen = driver.lastSeen
  //     const dateBefore10Days = moment().subtract('10', 'days').format()
  //     if (lastSeen) {
  //       const lastSeenDayDiff = moment(lastSeen).diff(dateBefore10Days, 'days')
  //       const isPositiveLastSeen = lastSeenDayDiff > 0
  //       const isLastSeenUnder10Days = isPositiveLastSeen && lastSeenDayDiff <= 10
  //       return isLastSeenUnder10Days
  //     }
  //     else {
  //       return false
  //     }
  //   })
  // }

  return (
    <>
      {drivers.map(driver => {
        const coords = {
          lat: driver.location.coordinates[1],
          lng: driver.location.coordinates[0],
        }
        const lastSeen = driver.lastSeen
        const dateBefore10Days = moment().subtract('10', 'days').format()
        let isLastSeenUnder10Days = false
        if (lastSeen) {
          const lastSeenDayDiff = moment(lastSeen).diff(dateBefore10Days, 'days')
          const isPositiveLastSeen = lastSeenDayDiff > 0
          isLastSeenUnder10Days = isPositiveLastSeen && lastSeenDayDiff <= 10
        }
        const isShowDriverMarker = driver.isOnline || isLastSeenUnder10Days
        // return (
        //   isShowDriverMarker && <Marker
        //     key={driver._id}
        //     position={coords}
        //     icon={markerIcon(driver)}
        //     onClick={() => setSelectedDriver(driver)}
        //   />
        return (
          <Marker
            key={driver._id}
            position={coords}
            icon={markerIcon(driver)}
            onClick={() => setSelectedDriver(driver)}
          />
        )
      })}
    </>
  )
}
