import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  chakra,
  CloseButton,
  Heading,
  HStack,
  IconButton,
  Image,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPhoneAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useBooking } from '../contexts/BookingContext'
import moment from 'moment'

const MotionBox = motion(Box)

export default function DriverDetails({
  isOpen,
  isGrua,
  onClose,
  driver,
  fare = null,
  duration = null,
  distance = null,
}) {
  const margin = useBreakpointValue({ base: '0', lg: '8' })
  const width = useBreakpointValue({ base: '100vw', lg: '25vw' })
  const borderRadius = useBreakpointValue({ base: 'lg', md: 'lg' })
  const height = useBreakpointValue({ base: '20vh' })

  const navigate = useNavigate()

  const { t } = useTranslation()

  const base = {
    initial: { y: 100, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: 100, opacity: 0 },
    transition: {
      duration: 0.5,
      ease: [0.6, -0.05, 0.01, 0.9],
    },
  }

  const lg = {
    initial: { x: -250, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -250, opacity: 0 },
    transition: {
      duration: 0.5,
      ease: [0.6, -0.05, 0.01, 0.9],
    },
  }

  const { initial, animate, exit, transition } = useBreakpointValue({
    base: base,
    lg: lg,
  })

  console.log(fare, distance, duration)
  const { booking } = useBooking()

  const [driverArrivalTime, setDriverArrivalTime] = useState('')
  // const [driverAddress, setDriverAddress] = useState(null)

  useEffect(() => {
    async function calculateRouteBetweenDriverAndOrigin() {
      console.log(driver.location.coordinates)
      if (!booking || booking.origin?.address === '' || !driver) {
        return
      }
      try {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        /** @type google.maps.DirectionsResult */
        const result = await directionsService.route({
          origin: {
            lat: booking.origin.location.lat,
            lng: booking.origin.location.lng,
          },
          destination: {
            lat: driver.location.coordinates[1],
            lng: driver.location.coordinates[0],
          },
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        })
        console.log('result', result)
        console.log(result.routes[0].legs[0].duration.text)
        setDriverArrivalTime(result.routes[0].legs[0].duration.text)
        // getAddressFromCoordinates(driver.location.coordinates)

      } catch (error) {
        console.log('error', error.message)
        setDriverArrivalTime(
          t('driverDetailsConfirm.driverArrivalTimeNotFound')
        )
      }
    }

    calculateRouteBetweenDriverAndOrigin()
  }, [driver, booking])

  function handleBooking() {
    // alert('You booking has been completed.')
    console.log(booking, driver, fare, distance, duration)
    navigate(isGrua ? '/checkout-grua': '/checkout')
  }

  // const getAddressFromCoordinates = async (coordinates) => {
  //   try {
  //     const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[1]},${coordinates[0]}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
  //     const address = (res.data.results[0] ? res.data.results[0].formatted_address : null)
  //     setDriverAddress(address)
  //     return address
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <MotionBox
          margin={margin}
          minW={width}
          minH={height}
          bgColor='white'
          // bgColor='whiteAlpha.500'
          // backdropFilter='blur(6px)'
          position='absolute'
          bottom={{ base: 0, lg: 4 }}
          left={{ base: 0, lg: 8 }}
          borderRadius={borderRadius}
          padding={{ base: 4, md: 8 }}
          initial={initial}
          animate={animate}
          exit={exit}
          transition={transition}
          spacing={8}
        >
          <HStack alignItems='flex-start' justifyContent='space-between'>
            <VStack alignItems='flex-start'>
              <Heading size='md'>{driver.fullName}</Heading>
              <Heading size='sm'>
                {t('driverDetailsConfirm.registration_number')}{' '}
                {driver.vehicle.registrationNumber}
              </Heading>
              <Heading size='sm'>
                {driver.vehicle.brand || ''}
                <chakra.span ml={1} fontSize='sm'>
                  ({driver.vehicle.year || ''})
                </chakra.span>
              </Heading>
              {driver.speaksEnglish && (
                <Text>{t('driverDetailsConfirm.englishSpoken')} </Text>
              )}
              {driver.acceptCreditCard && (
                // <>Credit Card Accepted</>
                <Text>{t('driverDetailsConfirm.acceptCreditCard')}</Text>
              )}
              {driverArrivalTime ? (
                // <>Arrives in: {driverArrivalTime}</>
                <Text fontSize='lg' textColor='red.500' fontWeight='bold'>
                  {t('driverDetailsConfirm.arivesIn')} {driverArrivalTime}
                </Text>
              ) : (
                <Spinner />
              )}
              {fare && (
                <>
                  {/* <Stat>
                    <StatLabel>Distance: {distance}</StatLabel>
                    <StatLabel>Time: {duration}</StatLabel>
                    <StatNumber display='inline-flex' alignItems='baseline'>
                      {driver.vehicle.type === VEHICLE_TYPE.TAXI
                        ? fare.totalCost
                        : Math.ceil(fare.totalCost * fare.multiplier)}
                      <chakra.span ml={2} fontWeight='medium' fontSize='sm'>
                        {fare.currency}
                      </chakra.span>
                    </StatNumber>
                  </Stat> */}
                  <Button colorScheme='primary' style={{ marginBottom: '6px' }} onClick={handleBooking}>
                    {/* Confirm Booking */}
                    {t('driverDetailsConfirm.confirmBooking')}
                  </Button>
                  <HStack>
                    <IconButton
                      aria-label='call-driver'
                      colorScheme='green'
                      as='a'
                      href={`tel:${driver.phoneNumber}`}
                      icon={<FaPhoneAlt />}
                      size='sm'
                    />
                    <Text>{driver.phoneNumber}</Text>
                    {
                      !driver.isOnline && driver.lastSeen ?
                        <Text style={{ marginLeft: '12px' }} fontSize='xs'>{t('driverDetailsConfirm.lastSeen')} <br /> <strong>{moment(driver.lastSeen).format('YYYY-MM-DD HH:mm')}</strong></Text>
                        : null
                    }
                  </HStack>

                  {driver.premiumAdText && <HStack >
                    <Text
                      fontSize='md'
                      style={{
                        border: '3px solid #5a5a5a',
                        padding: '5px',
                        borderRadius: '4px',
                        wordBreak: 'break-word',
                        maxWidth: '300px',
                        marginTop: '6px',
                      }}
                      dangerouslySetInnerHTML={{ __html: driver.premiumAdText.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                    ></Text>
                  </HStack>}
                </>
              )}
            </VStack>
            <VStack alignItems='flex-end'>
              <Avatar
                size='xl'
                name={driver.fullName}
                src={driver.images.profile.url}
              />
              <AspectRatio ratio={16 / 9} minW={40}>
                <Image
                  borderRadius='sm'
                  aria-label={driver.vehicle.registrationNumber}
                  objectFit='fill'
                  src={driver.vehicle.images.vehicle.url}
                />
              </AspectRatio>
            </VStack>
          </HStack>
          <HStack justifyContent='flex-end' mt={2}>
            <CloseButton onClick={onClose} alignSelf='flex-end' />
          </HStack>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}
