import { faker } from '@faker-js/faker'

const driver = () => ({
  _id: faker.datatype.uuid(),
  phoneVerified: true,
  fullName: faker.name.findName(),
  postalAddress: {
    street: faker.address.streetAddress(),
    houseNumber: faker.address.streetName(),
    neighbourhood: faker.address.secondaryAddress(),
    city: faker.address.city(),
    postalCode: faker.address.zipCode(),
    country: 'Chile',
  },
  personalId: faker.datatype.string(),
  drivingLicenseNumber: faker.datatype.string(),
  drivingLicenseValidUntil: faker.date.future(),
  acceptCreditCard: faker.datatype.boolean(),
  images: {
    profile: {
      name: faker.datatype.string(5),
      url: faker.image.avatar(),
    },
    personalId: {
      name: faker.datatype.string(5),
      url: faker.image.business(),
    },
    drivingLicense: {
      name: faker.datatype.string(5),
      url: faker.image.business(),
    },
  },
  vehicle: {
    type: Math.random() < 0.5 ? 'TAXI' : 'EXECUTIVE_TAXI',
    registrationNumber: faker.vehicle.vin(),
    registrationValidUntil: faker.date.future(),
    insuranceValidUntil: faker.date.future(),
    passengerCapacity: faker.datatype.number({ min: 2, max: 9 }),
    images: {
      vehicle: {
        name: faker.datatype.string(5),
        url: faker.image.transport(),
      },
      registration: {
        name: faker.datatype.string(5),
        url: faker.image.business(),
      },
      insurance: {
        name: faker.datatype.string(5),
        url: faker.image.business(),
      },
    },
  },
  verificationStatus: 'APPROVED',
  isAvailable: true,
  phoneNumber: faker.phone.phoneNumber(),
  uid: faker.datatype.uuid(),
  __v: 0,
  location: {
    type: 'Point',
    coordinates: [faker.address.longitude(), faker.address.latitude()],
  },
})

export const drivers = Array.from({ length: 20 }).map(() => driver())

// console.log(drivers.map(driver => console.log(driver._id)))
// console.log(driver()._id)
