export const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'es',
    name: 'Español',
    country_code: 'es',
  },
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },

  {
    code: 'it',
    name: 'Italiano',
    country_code: 'it',
  },
  {
    code: 'de',
    name: 'Deutsch',
    country_code: 'de',
  },
  {
    code: 'pt',
    name: 'Portuguese',
    country_code: 'pt',
  },
  {
    code: 'he',
    name: 'Hebrew',
    country_code: 'he',
  },
  {
    code: 'ru',
    name: 'Russian',
    country_code: 'ru',
  },
  // {
  //   code: 'ar',
  //   name: 'العربية',
  //   dir: 'rtl',
  //   country_code: 'sa',
  // },
]
