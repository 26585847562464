import { Box, Button, Checkbox, FormControl, FormLabel, HStack, Heading, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Select, Stack, Tag, TagLabel, Text, VStack, chakra, useDisclosure, useToast } from "@chakra-ui/react";
import { useDebouncedValue, useViewportSize } from "@mantine/hooks";
import { DirectionsRenderer, DirectionsService, GoogleMap, Marker } from "@react-google-maps/api";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFilter, FaTimes } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import useSWR from "swr";
import Checkout from "../components/Checkout";
import DriverDetails from "../components/DriverDetails";
import Drivers from "../components/Drivers";
import LanguageSelector from "../components/LanguageSelector";
import { useBooking } from "../contexts/BookingContext";
import { useGeolocation } from "../contexts/GeolocationContext";
import api from "../lib/api-client";
import { atlasMapStyle, snazzyMapStyle } from "../lib/map-style";
import { markerIcon } from "../lib/market-icon";
import { DRIVER_GENDER, VEHICLE_TYPE } from "../utils/config";
import { drivers as baseDrivers } from "../utils/seed-drivers";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 33.4489,
  lng: 70.6693,
};

export default function ConfirmBooking() {
  const { t } = useTranslation();

  const { booking, distanceText, durationText, directionsResponse, fare, setDriver } = useBooking();
  const navigate = useNavigate();
  const toast = useToast();
  const { position } = useGeolocation();
  const [map, setMap] = useState(
    /** @type google.maps.Map*/
    (null)
  );
  const [selectedDriver, setSelectedDriver] = useState(null);
  // const [drivers, setDrivers] = useState(() => baseDrivers)
  const [filteredDrivers, setFilteredDrivers] = useState([]);

  const { isOpen: shouldShowTaxi, onToggle: toggleShouldShowTaxi } = useDisclosure();

  console.log("directionsResponse", directionsResponse);

  useEffect(() => {
    setDriver(selectedDriver);
  }, [selectedDriver]);

  // useEffect(() => {
  //   if (booking === null) {
  //     navigate('/booking')
  //   }
  // }, [booking, navigate])

  useEffect(() => {
    if (position != null && map != null) {
      map.panTo({
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      });
    }
  }, [position, map]);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const directionsRendererOptions = useMemo(() => {
    return {
      directions: directionsResponse,
    };
  }, [directionsResponse]);

  const [mapCenter, setMapCenter] = useState({ latitude: 0, longitude: 0 });

  const [data, setData] = useState([])

  const [debouncedMapCenter] = useDebouncedValue(mapCenter, 500);

  useEffect(() => {
    console.log("debouncedMapCenter", debouncedMapCenter);
  }, [debouncedMapCenter]);

  const { data: drivers, error: apiError } = useSWR(debouncedMapCenter ? `/near-me-drivers?lng=${debouncedMapCenter.longitude}&lat=${debouncedMapCenter.latitude}` : null, api);

  useEffect(() => {
    setData(drivers?.filter(driver => driver.vehicle.type === 'GRUA'))
    // console.log("drivers data", drivers);
  }, [drivers]);

  const [vehicleType, setVehicleType] = useState(""); //Empty means all
  const [passengerCapacity, setPassengerCapacity] = useState(""); //0 means all
  const [acceptCreditCard, setAcceptCreditCard] = useState("2"); //2 means both
  const [gender, setGender] = useState(DRIVER_GENDER.all);
  const [speaksEnglish, setSpeaksEnglish] = useState(false);

  useEffect(() => {
    let filtered = data ?? [];
    console.log(passengerCapacity, vehicleType, acceptCreditCard, gender);
    if (vehicleType !== "" && vehicleType !== "ALL") {
      filtered = filtered.filter((driver) => driver.vehicle.type === vehicleType);
    }
    if (acceptCreditCard === "1") {
      filtered = filtered.filter((driver) => driver.acceptCreditCard === true);
    } else if (acceptCreditCard === "0") {
      filtered = filtered.filter((driver) => driver.acceptCreditCard === false);
    }
    if (passengerCapacity !== "") {
      filtered = filtered.filter((driver) => driver.vehicle.passengerCapacity >= passengerCapacity);
    }
    if (gender !== DRIVER_GENDER.all) {
      console.log(gender);
      filtered = filtered.filter((driver) => driver.gender === gender);
    }
    if (speaksEnglish === true) {
      filtered = filtered.filter((driver) => driver.speaksEnglish === true);
    }
    setFilteredDrivers(filtered);
  }, [vehicleType, passengerCapacity, acceptCreditCard, gender, speaksEnglish, data]);

  useEffect(() => {
    console.log("filteredLength", filteredDrivers.length);
  }, [filteredDrivers]);

  console.log("position", position);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { height, width } = useViewportSize();

  if (!booking) {
    return <Navigate to="/booking-grua" replace={true} />;
  }

  return (
    <VStack w={width} h={height}>
      <Box boxSize={"100%"} position="absolute" top={0} left={0}>
        <GoogleMap
          onCenterChanged={() =>
            map &&
            setMapCenter({
              latitude: map.getCenter().lat(),
              longitude: map.getCenter().lng(),
            })
          }
          onLoad={onMapLoad}
          id="direction-example"
          mapContainerStyle={containerStyle}
          zoom={process.env.NODE_ENV === "production" ? 15 : 1}
          center={center}
          options={{
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            clickableIcons: false,
            styles: snazzyMapStyle,
          }}
        >
          {directionsResponse && <DirectionsRenderer options={directionsRendererOptions} />}
          {filteredDrivers &&
            shouldShowTaxi &&
            filteredDrivers.map((driver) => {
              const coords = {
                lat: parseFloat(driver.location.coordinates[1]),
                lng: parseFloat(driver.location.coordinates[0]),
              };
              const lastSeen = driver.lastSeen;
              const dateBefore10Days = moment().subtract("10", "days").format();
              let isLastSeenUnder10Days = false;
              if (lastSeen) {
                const lastSeenDayDiff = moment(lastSeen).diff(dateBefore10Days, "days");
                const isPositiveLastSeen = lastSeenDayDiff > 0;
                isLastSeenUnder10Days = isPositiveLastSeen && lastSeenDayDiff <= 10;
              }
              const isShowDriverMarker = driver.isOnline || isLastSeenUnder10Days;
              return isShowDriverMarker && <Marker key={driver._id} position={coords} icon={markerIcon(driver)} onClick={() => setSelectedDriver(driver)} />;
            })}
        </GoogleMap>
      </Box>
      {!shouldShowTaxi && (
        <Button
          size="lg"
          colorScheme="primary"
          position="absolute"
          bottom={2}
          onClick={() => {
            toggleShouldShowTaxi();
            map?.setZoom(15);
            map?.panTo({
              lat: booking?.origin?.location?.lat,
              lng: booking?.origin?.location?.lng,
            });
          }}
        >
          {/* Find a taxi */}
          {t("page3.findGrua")}
        </Button>
      )}
      <VStack my={2} p={4} borderRadius="lg" shadow="base" bgColor="white" zIndex="modal" alignItems="flex-start">
        <Heading fontSize={{ base: "sm", md: "md" }} fontWeight="normal">
          <Tag variant="subtle" colorScheme="red" mr={2}>
            <TagLabel letterSpacing="wider">{t("page3.a-orig")}</TagLabel>
          </Tag>
          {booking?.origin?.address}
        </Heading>
        <Heading fontSize={{ base: "sm", md: "md" }} fontWeight="normal">
          <Tag variant="subtle" colorScheme="red" mr={2}>
            <TagLabel letterSpacing="wider">{t("page3.b-dest")}</TagLabel>
          </Tag>
          {booking?.destination?.address}
        </Heading>
        <HStack justifyContent="space-between" alignSelf="stretch">
          <HStack spacing={4}>
            {distanceText && <Text>{distanceText}</Text>}
            {durationText && <Text>{durationText}</Text>}

            {/* {fare && (
              <Text>
                {t('page3.taxi')}: {fare.totalCost}
                <chakra.span fontSize='xs' ml={1}>
                  {fare.currency}
                </chakra.span>
              </Text>
            )} */}
            {/* {fare && (
              <Text>
                {t('page3.premium')}:{' '}
                {Math.ceil(fare.totalCost * fare.multiplier)}
                <chakra.span fontSize='xs' ml={1}>
                  {fare.currency}
                </chakra.span>
              </Text>
            )} */}
          </HStack>

          {/* <IconButton
            size='sm'
            alignSelf='flex-end'
            aria-label='current-location'
            icon={<FaFilter />}
            onClick={onOpen}
          /> */}
        </HStack>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {/* Filters */}
            {t("filter.filter")}
          </ModalHeader>{" "}
          <ModalCloseButton />
          <ModalBody>
            <VStack bgColor="white" padding={4} borderRadius="lg" zIndex="modal" spacing={{ base: 2, md: 8 }}>
              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {/* Taxi type */}
                  {t("filter.taxiType")}
                </FormLabel>
                <Select onChange={(e) => setVehicleType(e.target.value)} defaultValue={vehicleType}>
                  <option value="ALL">{t("filter.all")}</option>
                  <option value={VEHICLE_TYPE.TAXI}>Taxi</option>
                  <option value={VEHICLE_TYPE.EXECUTIVE_TAXI}>Premium</option>
                  {/* {Object.values(VEHICLE_TYPE).map(item => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))} */}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {/* Minimum Passengers */}
                  {t("filter.minimumPassengers")}
                </FormLabel>
                <Input
                  type="number"
                  name="passengers"
                  id="passengers"
                  value={passengerCapacity}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setPassengerCapacity("");
                      return;
                    }
                    setPassengerCapacity(parseInt(e.target.value));
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {/* Accepts credit card */}
                  {t("filter.acceptCreditCard")}
                </FormLabel>
                <RadioGroup value={acceptCreditCard} onChange={setAcceptCreditCard}>
                  <Stack direction="row">
                    {/* <Radio value='1'>Yes</Radio>
                    <Radio value='0'>No</Radio>
                    <Radio value='2'>Both</Radio> */}
                    <Radio value="1">{t("filter.yes")}</Radio>
                    <Radio value="0">{t("filter.no")}</Radio>
                    <Radio value="2">{t("filter.all")}</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  {/* Gender */}
                  {t("filter.gender")}
                </FormLabel>
                <RadioGroup value={gender} onChange={setGender}>
                  <Stack direction="row">
                    <Radio value={DRIVER_GENDER.all}>
                      {/* {DRIVER_GENDER.all} */}
                      {t("filter.all")}
                    </Radio>
                    <Radio value={DRIVER_GENDER.male}>
                      {/* {DRIVER_GENDER.male} */}
                      {t("filter.male")}
                    </Radio>
                    <Radio value={DRIVER_GENDER.female}>
                      {/* {DRIVER_GENDER.female} */}
                      {t("filter.female")}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Checkbox size="lg" alignSelf="flex-start" onChange={(e) => setSpeaksEnglish(e.target.checked)}>
                {t("filter.englishSpoken")}
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setAcceptCreditCard("2");
                setPassengerCapacity("");
                setVehicleType("ALL");
                setGender(DRIVER_GENDER.all);
              }}
            >
              {/* Clear filter */}
              {t("filter.clearFilter")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DriverDetails driver={selectedDriver} fare={fare} distance={distanceText} duration={durationText} isOpen={selectedDriver} onClose={() => setSelectedDriver(null)} isGrua={true} />
    </VStack>
  );
}
