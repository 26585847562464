import { SkeletonText, VStack } from '@chakra-ui/react'
import { useJsApiLoader } from '@react-google-maps/api'
import { useMemo } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { SWRConfig } from 'swr'
import api from './lib/api-client'
import BookingPage from './pages/BookingPage'
import BookingPageGrua from './pages/BookingPageGrua'
import CheckoutPage from './pages/CheckoutPage'
import CheckoutPageGrua from './pages/CheckoutPageGrua'
import ConfirmBookingGrua from './pages/ConfirmBookingGrua'
import ConfirmBooking from './pages/ConfirmBooking'
import HelpPage from './pages/HelpPage'
import HomePage from './pages/HomePage'
import BookingLaterPage from './pages/BookingLaterPage'

function App() {
  const libraries = useMemo(() => ['places'], [])
  const { isLoaded } = useJsApiLoader({
    // @ts-ignore
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  if (!isLoaded) {
    return (
      <VStack>
        <SkeletonText />
      </VStack>
    )
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/booking' element={<BookingPage />} />
        <Route path='/booking-later' element={<BookingLaterPage />} />
        <Route path='/booking-grua' element={<BookingPageGrua />} />
        <Route path='/confirm-booking' element={<ConfirmBooking />} />
        <Route path='/confirm-booking-grua' element={<ConfirmBookingGrua />} />
        <Route path='/checkout' element={<CheckoutPage />} />
        <Route path='/checkout-grua' element={<CheckoutPageGrua />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
