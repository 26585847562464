import { useToast } from '@chakra-ui/react'
import { useJsApiLoader } from '@react-google-maps/api'
import { type } from '@testing-library/user-event/dist/type'
import { useState, useEffect, createContext, useContext, useMemo } from 'react'
import api from '../lib/api-client'

// @ts-ignore
const BookingContext = createContext()

export const useBooking = () => useContext(BookingContext)

export default function BookingContextProvider({ children }) {
  const [booking, setBooking] = useState(null)

  const [driver, setDriver] = useState(null)
  const [distanceText, setDistanceText] = useState('')
  const [durationText, setDurationText] = useState('')
  const [distanceInMeters, setDistanceInMeters] = useState(0)
  const [durationInSeconds, setDurationInSeconds] = useState(0)
  const [fare, setFare] = useState({})

  const [trip, setTrip] = useState(null)

  const libraries = useMemo(() => ['places'], [])
  const { isLoaded } = useJsApiLoader({
    // @ts-ignore
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  const [directionsResponse, setDirectionsResponse] = useState(
    /** @type google.maps.DirectionsResult */ (null)
  )

  useEffect(() => {
    async function calculateRoute() {
      if (
        !booking ||
        booking.origin?.address === '' ||
        booking.destination?.address === '' ||
        !isLoaded
      ) {
        return
      }
      try {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const result = await directionsService.route({
          origin: booking.origin.address,
          destination: booking.destination.address,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(result)
      } catch (error) {
        console.log(
          'error calculating directions in booking context',
          error.message
        )
        setDirectionsResponse(null)
      }
    }
    calculateRoute()
  }, [booking, isLoaded])

  useEffect(() => {
    if (
      !booking ||
      !durationInSeconds ||
      !distanceInMeters ||
      !booking.origin?.location?.lat ||
      !booking.origin?.location?.lng ||
      !booking.destination?.location?.lat ||
      !booking.destination?.location?.lng
    ) {
      return
    }
    // Make a call to get Fare...
    const distance = distanceInMeters
    const duration = durationInSeconds
    const location = [booking.origin.location.lng, booking.origin.location.lat]
    console.log("distance, duration, location",distance, duration, location)
    api
      .post('/calculate-fare', { location, distance, duration })
      .then(response => {
        console.log("response",response)
        setFare(response)
      })
      .catch(error => console.log(error.message))
  }, [booking, directionsResponse, distanceInMeters, durationInSeconds])

  useEffect(() => {
    if (directionsResponse === null) {
      return
    }
    setDistanceText(directionsResponse.routes[0].legs[0].distance.text)
    setDistanceInMeters(directionsResponse.routes[0].legs[0].distance.value)
    setDurationText(directionsResponse.routes[0].legs[0].duration.text)
    setDurationInSeconds(directionsResponse.routes[0].legs[0].duration.value)
  }, [directionsResponse])

  const value = {
    booking,
    setBooking,
    driver,
    setDriver,
    distanceText,
    durationText,
    fare,
    directionsResponse,
    distanceInMeters,
    durationInSeconds,
  }
  return (
    <BookingContext.Provider value={value}>{children}</BookingContext.Provider>
  )
}
/* 
process.env.NODE_ENV === 'production'
  ? null
  : {
      name: 'truly mittal',
      phoneNumber: '++5656987654321',
      origin: {
        location: {
          lat: 26.8764446,
          lng: 81.0422998,
        },
        address:
          'V2GR+JV9, 05A, Prembagh, Buddhvihar colony, Chinhat, Lucknow, Uttar Pradesh 226028, India',
      },
      destination: {
        location: {
          lat: 26.8541536,
          lng: 80.94478269999999,
        },
        address: 'Hazratganj, Lucknow, Uttar Pradesh 226001, India',
      },
    }
     */
