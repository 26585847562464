import { useDisclosure } from '@chakra-ui/react'
import { useContext, createContext, useState } from 'react'

const DriverDetailContext = createContext({})

export const useDriverDetails = () => useContext(DriverDetailContext)

export default function DriverDetailContextProvider({ children }) {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const [selectedDriver, setSelectedDriver] = useState(null)

  const value = {
    isOpen,
    onOpen,
    onClose,
    onToggle,
    selectedDriver,
    setSelectedDriver,
  }
  return (
    <DriverDetailContext.Provider value={value}>
      {children}
    </DriverDetailContext.Provider>
  )
}
