import axios from 'axios'

const api = axios.create({
  baseURL: 'https://api.radar.taxi/v1/client',
  // baseURL: 'http://localhost:4000/v1/client',
  withCredentials: true,
})

/** @type import('axios').AxiosInterceptorManager */
api.interceptors.response.use(
  (/** @type import('axios').AxiosResponse */ response) => {
    return response.data
  },

  (/** @type import('axios').AxiosError */ error) => {
    if (error.response) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error)
  }
)

export default api
